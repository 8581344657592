import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';
import localeES from '@angular/common/locales/es';
import localePT from '@angular/common/locales/pt';
import localeSR from '@angular/common/locales/sr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService } from '@angular/fire/compat/analytics';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { FormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { QRCodeModule } from 'angularx-qrcode';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxPayPalModule } from 'ngx-paypal';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthContainerComponent } from './components/auth-container/auth-container.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BottomImageComponent } from './components/bottom-image/bottom-image.component';
import { BottomPopupComponent } from './components/bottom-popup/bottom-popup.component';
import { CardComponent } from './components/card/card.component';
import { CollectionComponent } from './components/collection/collection.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangeSearchComponent } from './components/date-range-search/date-range-search.component';
import { ButtonComponent } from './components/elements/button/button.component';
import { DescriptionComponent } from './components/elements/description/description.component';
import { IconBlockComponent } from './components/elements/icon-block/icon-block.component';
import { IconComponent } from './components/elements/icon/icon.component';
import { TitleComponent } from './components/elements/title/title.component';
import { ToggleSwitchComponent } from './components/elements/toggle-switch/toggle-switch.component';
import { EventInfoBlockComponent } from './components/event-info-block/event-info-block.component';
import { FormComponent } from './components/form/form.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCardComponent } from './components/image-card/image-card.component';
import { InfoBlockComponent } from './components/info-block/info-block.component';
import { LanguageComponent } from './components/language/language.component';
import { MessageContainerComponent } from './components/message-container/message-container.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { ModalComponent } from './components/modal/modal.component';
import { OrdersDetailComponent } from './components/orders-detail/orders-detail.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PackageListingComponent } from './components/package-listing/package-listing.component';
import { PackagesComponent } from './components/packages/packages.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PaymentPopupComponent } from './components/payment-popup/payment-popup.component';
import { PopupWithLinkComponent } from './components/popup-with-link/popup-with-link.component';
import { PopupComponent } from './components/popup/popup.component';
import { QrCodePopupComponent } from './components/qr-code-popup/qr-code-popup.component';
import { SectionsGuideComponent } from './components/sections-guide/sections-guide.component';
import { SelectionPopupComponent } from './components/selection-popup/selection-popup.component';
import { TableComponent } from './components/table/table.component';
import { UploadPopupComponent } from './components/upload-popup/upload-popup.component';
import { BlockNegativeNumbersDirective } from './directives/block-negative-numbers.directive';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { ProtectDisabledDirective } from './directives/protect-disabled.directive';
import { ProtectDisplayDirective } from './directives/protect-display.directive';
import { AdminProfilePageComponent } from './pages/admin-profile-page/admin-profile-page.component';
import { AdminStatisticsPageComponent } from './pages/admin-statistics-page/admin-statistics-page.component';
import { AdminUsersControlPageComponent } from './pages/admin-users-control-page/admin-users-control-page.component';
import { ClientEventPageComponent } from './pages/client-event-page/client-event-page.component';
import { ClientEventsPageComponent } from './pages/client-events-page/client-events-page.component';
import { ClientLoginPageComponent } from './pages/client-login-page/client-login-page.component';
import { EventDetailComponent } from './pages/event-detail/event-detail.component';
import { EventPageComponent } from './pages/event-page/event-page.component';
import { ClientComponent } from './pages/layout/client/client.component';
import { DefaultComponent } from './pages/layout/default/default.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PackagesPageComponent } from './pages/packages-page/packages-page.component';
import { PasswordResetPageComponent } from './pages/password-reset-page/password-reset-page.component';
import { PaymentFailedPageComponent } from './pages/payment-failed-page/payment-failed-page.component';
import { PaymentSuccessPageComponent } from './pages/payment-success-page/payment-success-page.component';
import { RegistrationPageComponent } from './pages/registration-page/registration-page.component';
import { ShoppingCartPageComponent } from './pages/shopping-cart-page/shopping-cart-page.component';
import { UserActiveEventsPageComponent } from './pages/user-active-events-page/user-active-events-page.component';
import { UserAutoPackageSelectComponent } from './pages/user-auto-package-select/user-auto-package-select.component';
import { UserCreatedEventsPageComponent } from './pages/user-created-events-page/user-created-events-page.component';
import { UserEventInfoPageComponent } from './pages/user-event-info-page/user-event-info-page.component';
import { UserEventsPageComponent } from './pages/user-events-page/user-events-page.component';
import { UserFinishedOrdersPageComponent } from './pages/user-finished-orders-page/user-finished-orders-page.component';
import { UserInactiveEventsPageComponent } from './pages/user-inactive-events-page/user-inactive-events-page.component';
import { UserOrderDetailsPageComponent } from './pages/user-order-details-page/user-order-details-page.component';
import { UserOrdersPageComponent } from './pages/user-orders-page/user-orders-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { UserPurchasesPageComponent } from './pages/user-purchases-page/user-purchases-page.component';
import { UserStatisticsPageComponent } from './pages/user-statistics-page/user-statistics-page.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { PopupDialogService } from './services/popup-dialog.service';
import { CouponAutoSelectComponent } from './pages/coupon-auto-select/coupon-auto-select.component';
import { CouponGeneratorComponent } from './pages/coupon-generator/coupon-generator.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeDE);
registerLocaleData(localeSR);
registerLocaleData(localeES);
registerLocaleData(localePT);
registerLocaleData(localeEN);

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    MainPageComponent,
    RegistrationPageComponent,
    PopupComponent,
    FormComponent,
    ButtonComponent,
    AdminUsersControlPageComponent,
    ToggleSwitchComponent,
    LanguageComponent,
    HeaderComponent,
    UserEventsPageComponent,
    NotFoundPageComponent,
    TableComponent,
    PaginationComponent,
    ModalComponent,
    TitleComponent,
    DescriptionComponent,
    MessageModalComponent,
    ConfirmationPopupComponent,
    ProtectDisabledDirective,
    ClientLoginPageComponent,
    ProtectDisplayDirective,
    ClientEventPageComponent,
    BottomPopupComponent,
    ShoppingCartPageComponent,
    CardComponent,
    CollectionComponent,
    SectionsGuideComponent,
    DateRangeSearchComponent,
    FileDragNDropDirective,
    PackageListingComponent,
    DatePickerComponent,
    BlockNegativeNumbersDirective,
    PackagesComponent,
    OrdersDetailComponent,
    OrdersComponent,
    PackagesPageComponent,
    DefaultComponent,
    ClientComponent,
    AuthContainerComponent,
    PopupWithLinkComponent,
    PaymentPopupComponent,
    EventPageComponent,
    ImageCardComponent,
    SelectionPopupComponent,
    PaymentSuccessPageComponent,
    PaymentFailedPageComponent,
    MessageContainerComponent,
    QrCodePopupComponent,
    InfoBlockComponent,
    AdminStatisticsPageComponent,
    UploadPopupComponent,
    UserStatisticsPageComponent,
    PasswordResetPageComponent,
    ClientEventsPageComponent,
    UserActiveEventsPageComponent,
    UserCreatedEventsPageComponent,
    UserInactiveEventsPageComponent,
    UserOrdersPageComponent,
    UserFinishedOrdersPageComponent,
    EventDetailComponent,
    UserOrderDetailsPageComponent,
    BottomImageComponent,
    IconComponent,
    IconBlockComponent,
    UserPurchasesPageComponent,
    DateFormatPipe,
    UserEventInfoPageComponent,
    UserProfilePageComponent,
    AdminProfilePageComponent,
    EventInfoBlockComponent,
    BackButtonComponent,
    UserAutoPackageSelectComponent,
    CouponAutoSelectComponent,
    CouponGeneratorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    InlineSVGModule.forRoot(),
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    MatDialogModule,
    NgClickOutsideDirective,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatMenuModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPayPalModule,
    QRCodeModule,
    InfiniteScrollModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LOCALE_ID, useValue: 'sr' },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: LOCALE_ID, useValue: 'en' },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        enterAnimationDuration: 200,
        exitAnimationDuration: 200,
      },
    },
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    ScreenTrackingService,
    DatePipe,
    PopupDialogService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
