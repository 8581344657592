import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import firebase from 'firebase/compat/app';
import { shortenFileName } from 'libs/common/utils/FileUtils';
import { ButtonActionType, ButtonType } from 'src/app/interfaces/Button.enum';
import { Button } from 'src/app/interfaces/Button.interface';
import { DescriptionType } from 'src/app/interfaces/Description.enum';
import { ErrorMessagesType } from 'src/app/interfaces/ErrorMessages.enum';
import { ErrorMessages } from 'src/app/interfaces/ErrorMessages.interface';
import { FormFieldsId, FormInputId, FormInputType, FormLabelColor, FormType } from 'src/app/interfaces/Form.enum';
import { Form, FormCredentials } from 'src/app/interfaces/Form.interface';
import { Role } from 'src/app/interfaces/Role.enum';
import { ImageUploadService } from 'src/app/services/image-upload.service';
import { PopupDialogService } from 'src/app/services/popup-dialog.service';
import { TrackingService } from 'src/app/services/tracing.service';
import { ValidationService } from 'src/app/services/validation.service';
import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnChanges {
  @Input() formInfo: Form;
  @Input() filledInputs!: FormCredentials;
  @Output() formDataEmitter = new EventEmitter<FormCredentials>();
  public formCredentials: FormCredentials;
  public isButtonDisabled: boolean;
  public errMsgs: ErrorMessages;
  public isPasswordVisible: boolean;
  public progress: number = 0;
  public isFileHovered: boolean = false;
  public blockFileUpload: boolean = false;
  public fileName: string = '';
  private previousSelectedDate: Date | null = null;
  public readonly ButtonType = ButtonType;
  public readonly ButtonActionType = ButtonActionType;
  public readonly FormType = FormType;
  public readonly FormInputType = FormInputType;
  public readonly FormInputId = FormInputId;
  public readonly descriptionType = DescriptionType;
  public Roles = Object.values(Role);

  constructor(
    private validation: ValidationService,
    private trackingService: TrackingService,
    private cdr: ChangeDetectorRef,
    private popupDialogService: PopupDialogService,
    private imageUploadService: ImageUploadService,

    @Optional() private popupDialog: MatDialogRef<PopupComponent>
  ) {
    this.formCredentials = {};

    this.isButtonDisabled = true;

    this.isPasswordVisible = false;
  }

  ngOnInit(): void {
    if (this.formInfo.type === FormType.CLIENT_EVENT_INPUT) {
      const refId = localStorage.getItem('eventId');
      if (refId) {
        this.formCredentials['eventRefId'] = refId;
        this.isButtonDisabled = false;
      }
    }
    if (this.filledInputs) {
      const copyFilledInputs = Object.assign({}, this.filledInputs);
      this.formCredentials = copyFilledInputs;
      this.previousSelectedDate = this.getDate();
      this.cdr.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filledInputs']?.currentValue) {
      if (this.filledInputs) {
        const copyFilledInputs = Object.assign({}, this.filledInputs);
        this.formCredentials = copyFilledInputs;
        this.cdr.detectChanges();
      }
    }
  }

  // Password show/hide
  public togglePasswordShow(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  public getPasswordIcon(): string {
    return this.isPasswordVisible ? 'show-password-icon.svg' : 'hide-password-icon.svg';
  }

  private checkButtonAvailability(type?: any): void {
    this.isButtonDisabled = this.formInfo.containsErrorMsg
      ? (type ? !!this.errMsgs[type as ErrorMessagesType] : false) ||
        !this.validation.areRequiredFieldsFulfilled(this.formCredentials, this.formInfo.elements) ||
        !this.validation.hasErrorMsgs(this.formInfo.elements)
      : !this.validation.areRequiredFieldsFulfilled(this.formCredentials, this.formInfo.elements);
  }

  // Validation for inputs
  public validationFunc(type: any, value: string, required: boolean): void {
    this.errMsgs = this.formInfo.containsErrorMsg
      ? this.validation.validateInput(type as ErrorMessagesType, value, required)
      : {};

    this.checkButtonAvailability(type);
  }

  public hasError(type: any): boolean {
    return this.errMsgs && this.errMsgs[type as ErrorMessagesType] && this.errMsgs[type as ErrorMessagesType] !== '';
  }

  public errMsg(type: any): string {
    return this.errMsgs ? this.errMsgs[type as ErrorMessagesType] : '';
  }

  public submitForm(): void {
    switch (this.formInfo.type) {
      case FormType.ADD_EVENT:
        this.formCredentials['isActive'] = false;
        this.formCredentials['activeDate'] = '';
        this.formCredentials['mainImage'] = '';
        this.formCredentials['createdAt'] = firebase.firestore.Timestamp.fromDate(new Date());
        this.formCredentials['eventDate'] = this.formCredentials['eventDate']
          ? this.formCredentials['eventDate']
          : firebase.firestore.Timestamp.fromDate(new Date());
        break;
    }
    const formCredentialsCopy = Object.assign({}, this.formCredentials);
    this.formDataEmitter.emit(formCredentialsCopy);

    this.isButtonDisabled = true;
    switch (this.formInfo.type) {
      case FormType.LOGIN:
        this.formCredentials['password'] = '';
        this.trackingService.logEvent('button_click', { button_name: FormType.LOGIN });
        break;
      case FormType.REGISTRATION:
        this.formCredentials = {};
        this.trackingService.logEvent('button_click', { button_name: FormType.REGISTRATION });
        break;
      case FormType.PASSWORD_RESET:
        this.trackingService.logEvent('button_click', { button_name: FormType.PASSWORD_RESET });
        break;
      case FormType.EDIT_USER_POPUP:
        this.trackingService.logEvent('button_click', { button_name: FormType.EDIT_USER_POPUP });
        break;
      case FormType.EDIT_PROFILE:
        this.trackingService.logEvent('button_click', { button_name: FormType.EDIT_PROFILE });
        break;
      case FormType.CLIENT_LOGIN:
        this.formCredentials = {};
        this.trackingService.logEvent('button_click', { button_name: FormType.CLIENT_LOGIN });
        break;
      case FormType.ADD_EVENT:
        this.trackingService.logEvent('button_click', { button_name: FormType.ADD_EVENT });
        break;
      case FormType.EDIT_EVENT:
        this.trackingService.logEvent('button_click', { button_name: FormType.EDIT_EVENT });
        break;
    }
  }

  public getSubmitButtonInfo(): Button {
    switch (this.formInfo.type) {
      case FormType.EDIT_USER_POPUP:
        return {
          text: 'admin_page.users_table.edit_user.form_popup.button.update_user',
          type: ButtonType.ROYAL_BLUE_SMALL,
          isDisabled: this.isButtonDisabled,
          buttonActionType: ButtonActionType.SUBMIT,
        };
      case FormType.ADD_EVENT:
        return {
          text: 'user_page.event.form_popup.add_event.button',
          type: ButtonType.ROYAL_BLUE_SMALL,
          isDisabled: this.isButtonDisabled,
          buttonActionType: ButtonActionType.SUBMIT,
        };
      case FormType.EDIT_EVENT:
        return {
          text: 'user_page.event.form_popup.edit_event.button',
          type: ButtonType.ROYAL_BLUE_SMALL,
          isDisabled: this.isButtonDisabled,
          buttonActionType: ButtonActionType.SUBMIT,
        };
      case FormType.CLIENT_DATA:
        return {
          text: this.formInfo.button,
          type: ButtonType.ROYAL_BLUE_SMALL,
          isDisabled: this.isButtonDisabled,
          buttonActionType: ButtonActionType.SUBMIT,
        };
      default:
        return {
          text: this.formInfo.button,
          type: ButtonType.ROYAL_BLUE,
          isDisabled: this.isButtonDisabled,
          buttonActionType: ButtonActionType.SUBMIT,
        };
    }
  }

  public getBtnContainerClasses(): string {
    switch (this.formInfo.type) {
      case FormType.EDIT_USER_POPUP:
        return 'w-full py-6 px-6 flex items-center justify-end gap-2 bg-blue-100 mt-6 sm:px-10';
      case FormType.ADD_EVENT:
        return 'w-full py-6 px-10 max-sm:px-6 flex items-center justify-end gap-2 bg-blue-100 mt-6';
      case FormType.EDIT_EVENT:
        return 'w-full py-6 px-10 max-sm:px-6 flex items-center justify-end gap-2 bg-blue-100 mt-6';
      case FormType.CLIENT_DATA:
        return 'w-full py-6 px-10 max-sm:px-6 flex items-center justify-end gap-2 bg-blue-100 mt-6';
      case FormType.EDIT_PROFILE:
        return 'pt-8 pb-2';
      case FormType.EDIT_PROFILE_POPUP:
        return 'p-10';
      default:
        return 'mt-8';
    }
  }

  public selectValue(value: string, id: FormInputId | FormFieldsId): void {
    this.formCredentials[id as FormInputId] = value as Role;
    switch (this.formInfo.type) {
      case FormType.EDIT_USER_POPUP:
        this.isButtonDisabled = false;
        break;
    }
  }

  public toggle(id: FormInputId | FormFieldsId): void {
    this.isButtonDisabled = false;
    this.formCredentials[id as FormFieldsId] = !this.formCredentials[id];
  }

  public onFileInputChange(event: any): void {
    if (!event.files.length) return;

    this.setImage(event.files);
  }

  public setImage(files: File[]): void {
    const file = files[0];
    if (!this.imageUploadService.validateImageType(file.type)) {
      this.popupDialogService.openMessageModalPopup({
        success: false,
        msg: 'user_page.message_modal.message.invalid_file',
      });
      return;
    }

    let copyFileName = file.name;
    if (copyFileName.length > 18) {
      const fileNameArr = copyFileName.split('.');
      copyFileName = fileNameArr[0].slice(0, 10) + '...' + fileNameArr[1];
    }
    this.fileName = copyFileName;

    this.validationFunc(FormInputId.IMAGE, '', false);

    this.formCredentials['image'] = file;
    this.fileName = file.name;
  }

  public onFileHover(isHovered: boolean): void {
    this.isFileHovered = isHovered;
  }

  public setDate(date: Date): void {
    this.checkButtonAvailability();
    switch (this.formInfo.type) {
      case FormType.ADD_EVENT:
        this.formCredentials['eventDate'] = date;
        break;
      case FormType.EDIT_EVENT:
        this.formCredentials['eventDate'] = date;
        break;
    }
  }

  public getDate(): Date | null {
    return this.filledInputs && 'eventDate' in this.filledInputs ? this.filledInputs['eventDate'] : null;
  }

  public getLabelColor(): string {
    switch (this.formInfo.labelColor) {
      case FormLabelColor.WHITE:
        return 'text-white';
      default:
        return 'text-zinc-900';
    }
  }

  public getErrorColor(): string {
    switch (this.formInfo.labelColor) {
      case FormLabelColor.WHITE:
        return 'text-white';
      default:
        return 'text-red-600';
    }
  }

  public getShorterFileName(fileName: string): string {
    return shortenFileName(fileName, 28);
  }
}
