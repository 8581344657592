<div class="container flex gap-2 mt-10 mb-8">
  <app-back-button
    [param]="getBackButtonParam()"
    [text]="'event_page.back_button'"
    [iconWidth]="'w-5'"
  ></app-back-button>
  <div *ngIf="event && eventImages.length" class="ml-auto">
    {{ 'event_page.number_of_image.text' | translate : { numberOfImages: eventImages.length } }}
  </div>
</div>
<div *ngIf="event && eventImages.length">
  <div class="container flex flex-wrap items-center justify-between gap-4">
    <div class="flex items-center gap-2 md:gap-5">
      <app-title [type]="TitleType.SMALL" [text]="event.name"></app-title>

      <div *ngIf="canEventBeEnabled()" class="flex items-center gap-2">
        <app-toggle-switch (click)="handleEnableSale()" [isOn]="event.isActive"></app-toggle-switch>
        <p class="font-semibold text-zinc-900">
          {{
            event.isActive
              ? ('event_page.toggle_sale.disable_sale' | translate)
              : ('event_page.toggle_sale.enable_sale' | translate)
          }}
        </p>
      </div>
    </div>

    <div class="flex flex-wrap items-center gap-2 md:gap-5">
      <app-button
        class="rounded-full"
        (click)="openQrCode()"
        [buttonInfo]="{
          type: isMobile ? ButtonType.ROYAL_BLUE_OUTLINE_ICON : ButtonType.ROYAL_BLUE_OUTLINE,
          text: isMobile ? '' : 'event_page.qr_code.button',
          icon: 'qr-code-icon.svg',
          iconColor: 'sky-700',
          customClasses: isMobile ? '' : 'flex gap-2.5 items-center whitespace-nowrap'
        }"
      ></app-button>
      <app-button
        class="rounded-full"
        (click)="openUploadPopup()"
        [buttonInfo]="{
          type: isMobile ? ButtonType.ROYAL_BLUE_ADD_ICON : ButtonType.ROYAL_BLUE_SMALL,
          text: isMobile ? '' : 'event_page.image_upload.button',
          icon: 'upload-icon.svg',
          customClasses: isMobile ? '' : 'flex gap-2.5 items-center whitespace-nowrap'
        }"
      ></app-button>
    </div>
  </div>

  <app-sections-guide [links]="links"></app-sections-guide>

  <router-outlet></router-outlet>
</div>

<div *ngIf="event && !eventImages.length">
  <app-message-container
    svgPath="./assets/icons/suprise-box-icon.svg"
    [topTitle]="event.name"
    title="event_page.no_images.title"
  >
    <app-button
      customButton
      class="rounded-full"
      (click)="openUploadPopup()"
      [buttonInfo]="{
        customClasses: 'px-8 gap-3 flex justify-center items-center',
        text: 'event_page.no_images.button',
        type: ButtonType.ROYAL_BLUE,
        icon: 'upload-icon.svg',
        iconColor: 'white',
        iconSize: 'w-5',
      }"
    ></app-button
  ></app-message-container>
</div>
