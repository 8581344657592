import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import * as ExifReader from 'exifreader'; // Import exifreader
import { nanoid } from 'nanoid';
import { Observable, Subject, from } from 'rxjs';
import { finalize, mergeMap, tap, toArray } from 'rxjs/operators';
import { ImageMIMEType } from '../interfaces/ImageType.enum';
import { ResizedImageData } from '../interfaces/ResizeImages.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadService {
  private progressSubject = new Subject<number>();
  public progress$: Observable<number> = this.progressSubject.asObservable();
  private fileUploadedSubject = new Subject<number>();
  public fileUploaded$: Observable<number> = this.fileUploadedSubject.asObservable();

  constructor(private storage: AngularFireStorage, private firestore: AngularFirestore) {}

  public uploadImage(images: File[], eventPath: string, newWidth: number = 270, imgQuality: number = 0.92): void {
    this.progressSubject.next(0);
    const overallStartTime = new Date();
    let totalOriginalSizeBytes = 0;
    let totalResizedSizeBytes = 0;
    let countOfImages: number = 0;

    from(images)
      .pipe(
        mergeMap(
          file => {
            totalOriginalSizeBytes += file.size;
            return this.uploadSingleImage(file, eventPath, newWidth, imgQuality).pipe(
              tap(resizedImageSizeKB => {
                totalResizedSizeBytes += resizedImageSizeKB * 1024; // converting KB back to bytes for accumulation
              }),
              finalize(() => {
                countOfImages++;
                this.progressSubject.next((100 / images.length) * countOfImages);
                this.fileUploadedSubject.next(countOfImages);
              })
            );
          },
          null,
          20
        ),
        toArray()
      )
      .subscribe({
        complete: () => {
          const overallEndTime = new Date();
          const elapsedSeconds = (overallEndTime.getTime() - overallStartTime.getTime()) / 1000; // convert to seconds

          console.log(`All ${images.length} uploads completed.`);
          console.log(`Total time elapsed: ${elapsedSeconds} seconds.`);
          console.log(
            `Total Original Size = ${this.formatBytes(totalOriginalSizeBytes)}, Total Resized Size = ${this.formatBytes(
              totalResizedSizeBytes
            )}`
          );
        },
      });
  }

  public resizeImage(file: File, newWidth: number, imgQuality: number): Observable<ResizedImageData> {
    return new Observable(observer => {
      let creationDate = new Date();
      const originalFileSizeKB = file.size / 1024;

      const startTime = this.formatDate(new Date(), 'Europe/Belgrade');
      console.log(`Image ${file.name} conversion started at: ${startTime}`);

      const reader = new FileReader();
      reader.onload = e => {
        const dataURL = e.target.result as string;

        // Convert data URL to ArrayBuffer
        const arrayBuffer = this.dataURLToArrayBuffer(dataURL);

        try {
          const exifData = ExifReader.load(arrayBuffer);

          const exifCreationDate = exifData?.['MetadataDate']?.value;
          if (exifCreationDate) {
            creationDate = new Date(exifCreationDate);
          }
        } catch (error) {
          console.warn(`Error loading Exif data: ${error}`);
        }

        const image = new Image();
        image.onload = () => {
          const mainCanvas = document.createElement('canvas');
          mainCanvas.width = newWidth;
          mainCanvas.height = (image.height * newWidth) / image.width;

          const ctx = mainCanvas.getContext('2d');
          ctx.drawImage(image, 0, 0, mainCanvas.width, mainCanvas.height);

          const resizedImage = mainCanvas.toDataURL('image/jpeg', imgQuality);
          const resizedImageSizeKB = (resizedImage.length * 0.75) / 1024;
          console.log(
            `Image ${file.name}: Original Size = ${originalFileSizeKB.toFixed(
              2
            )}KB, Resized Size = ${resizedImageSizeKB.toFixed(2)}KB`
          );

          observer.next({ resizedImage, creationDate, resizedImageSizeKB });
          observer.complete();
        };
        image.src = dataURL; // Set the data URL as the image source
      };
      reader.readAsDataURL(file);
    });
  }

  private uploadSingleImage(file: File, eventPath: string, newWidth: number, imgQuality: number): Observable<any> {
    return new Observable(observer => {
      this.resizeImage(file, newWidth, imgQuality).subscribe(resizedImageData => {
        const firestorePath = `${eventPath}/images/${nanoid()}`;

        const storageRef = this.storage.ref(firestorePath);
        const uploadTask = storageRef.putString(resizedImageData.resizedImage, 'data_url');

        uploadTask
          .snapshotChanges()
          .pipe(
            finalize(() => {
              storageRef.getDownloadURL().subscribe(downloadURL => {
                this.firestore
                  .doc(firestorePath)
                  .set({ name: file.name, url: downloadURL, creationDate: resizedImageData.creationDate.toISOString() })
                  .then(() => {
                    const endTime = this.formatDate(new Date(), 'Europe/Belgrade');
                    console.log(`Image ${file.name} uploaded and conversion completed at: ${endTime}`);
                    observer.next(resizedImageData.resizedImageSizeKB); // Emit the resized size
                    observer.complete();
                  })
                  .catch(error => {
                    console.error(error);
                    observer.error(error);
                  });
              });
            })
          )
          .subscribe();
      });
    });
  }

  public validateImageType(imageType: string): boolean {
    const allowedFileTypes = [ImageMIMEType.JPG, ImageMIMEType.JPEG, ImageMIMEType.PNG];

    return allowedFileTypes.some(allowedType => imageType.includes(allowedType));
  }

  private dataURLToArrayBuffer(dataURL: string): ArrayBuffer {
    const byteString = atob(dataURL.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    return arrayBuffer;
  }

  private formatDate(date: Date, timeZone: string): string {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: timeZone,
    }).format(date);
  }

  private formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }
}
